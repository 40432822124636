<template>
<LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>

<script>
import {
    Line as LineChartGenerator
} from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)

export default {
    name: 'LineChart',
    components: {
        LineChartGenerator
    },
    props: {
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        },
        chartData: {
            labels: {
                type: Array,
                default: () => []
            },
            datasets: {
                type: Array,
                default: () => []
            }
        }
    },
    data() {
        return {
            // chartData: {
            //   labels: [
            //     'January',
            //     'February',
            //     'March',
            //     'April',
            //     'May',
            //     'June',
            //     'July'
            //   ],
            //   datasets: [
            //     {
            //       label: 'Data One',
            //       backgroundColor: '#f87979',
            //       data: [40, 39, 10, 40, 39, 80, 40]
            //     }
            //   ]
            // },
            // chartOptions: {
            //   responsive: true,
            //   maintainAspectRatio: false,
            //   scales: {
            //   },
            // },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        // type: 'time',
                        // time: {
                        //     minUnit: 'month'
                        // }
                        ticks: {
                            autoSkip: false,
                            maxRotation: 75,
                            minRotation: 75
                        }

                    },
                    y: {
                        suggestedMax: 45,
                        ticks: {
                            stepSize: 5,
                            //max: 100
                        },
                    },
                },

            },

        }
    }
}
</script>
